<template>
  <div>
    <div v-for="(competitor, index) in competitors" :key="`${competitor.competitor}_${index}`">
      <div>
        <div class="d-flex align-center md-text">
          <img
            :src="'https://8181.integ.crawlo.com/favicon?size=xs&website=' + competitor.competitor"
            @error="replaceByDefault"
            :alt="competitor.competitor"
            height="25"
            class="mr-4 favicon-img"
          />
          <span class="md-text">
            <a :href="competitor.competitor" target="_balank">
              {{ competitor.competitor }}
            </a>
          </span>
        </div>
      </div>

      <v-row
        class="d-flex of-a"
        v-if="competitor.engagements != undefined && competitor.engagements.length > 0"
      >
        <v-col cols="3" v-for="(info, index) in competitor.engagements" :key="index">
          <v-card class="mx-4">
            <v-card-title class="lg-text font-weight-bold pb-0">
              {{ info.title }}
            </v-card-title>

            <v-card-text>
              <div class="text-right">
                <span class="font-weight-bold secondary--text md-text">
                  {{ singlePriceHandler(info.monthly) }}
                </span>
                <span class="sm-text">/month</span>
              </div>
              <div class="text-right">
                <span class="font-weight-bold secondary--text md-text"> x{{ info.duration }} </span>
                <span class="sm-text">/month</span>
              </div>
              <div class="text-right font-weight-bold md-text error--text">
                {{ singlePriceHandler(info.price) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div v-else class="text-center lg-text ma-4">No engagements found</div>
      <v-divider class="ma-4"></v-divider>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      competitors: Array,
    },
    data() {
      return {
        model: 1,
      }
    },
  }
</script>

<style scoped>
  .wmc {
    width: max-content;
  }
  .of-a {
    overflow-x: scroll;
  }
</style>
